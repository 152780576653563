
import React, { FC, useContext, useState, useCallback } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import FormSelect, { FormSelectProps } from '../../../components/form-select/form-select';
import FormInput, { FormInputProps } from '../../../components/form-input/form-input'
import SectionHeading from "../../../components/section-heading/section-heading";
import styled from 'styled-components';
import { Config, AuctionConfig, AvailableFromConfig } from '../../../types/config/config';
import { generateKey } from '../../../utils/keys';
import { Auctions } from '../../../types/listing/auctions';
import GLField from '../../../components/form/gl-field';
import GLForm from '../../../components/form/gl-form';
import { convertValidationJSON } from '../../../utils/forms/validation-adapter';
import { FormContext } from '../../../components/form/gl-form-context';
import FormDateField, { FormDateFieldProps } from '../../../components/form-date-field/form-date-field';


interface Props {
    config: Config,
    auctionConfig: AuctionConfig,
    auctionDetails: Auctions,
}

const Auction: FC<Props> = (props) => {

    const { auctionDetails, auctionConfig, config } = props;
    if (auctionDetails.eventTime) {
        auctionDetails.eventTimeHrs = auctionDetails.eventTime.split(':')[0];
        auctionDetails.eventTimeMinutes = auctionDetails.eventTime.split(':')[1];
    }
    const [data, setData] = useState<Auctions>(auctionDetails);
    const validations = {};
    const formControllerContext = useContext(FormContext);

    const transformAuctionDate = (date: any) => {
        // This function is used to transform the date to the correct format which is independent of GMT.
        // which avoids the date being displayed as the previous day.
        if (!date) {
            return null;
        }
        try {
            if (typeof date === 'string' && date.length > 0) {
                return new Date(date.slice(0, 10));
            } else if (date && date instanceof Date) {

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;

                return new Date(formattedDate);
            }
        } catch (e) {
            return null;
        }
        return null;
    }


    const changeHandler = (values: any) => {
        const temp: Auctions = data;
        temp.eventPlace = values.eventPlace;
        // Transformation is required because the date format provided in the config does not include the time part.
        // As a result, the time part defaults to 00:00:00.0000, which causes the date to be displayed as the previous day when axios serializes the data.
        temp.eventDate = transformAuctionDate(values.eventDate);
        temp.eventTime = `${values.eventTimeHrs ? values.eventTimeHrs : '00'}:${values.eventTimeMinutes ? values.eventTimeMinutes : '00'}`;
        formControllerContext.onFormChange({ auction: temp });
    }

    const getEventDate = () => {
        if (config && config.auction.EventDate) {
            const fieldConfig: AvailableFromConfig = config.auction.EventDate;
            return (
                <Col xs={3} id="eventDate">
                    <GLField<FormDateFieldProps> name="eventDate" use={FormDateField} {...fieldConfig} />
                </Col>
            );
        }
        return <></>;
    }
    return (
        <AuctionContainer>
            <GLForm initVals={auctionDetails}
                validationAdapter={convertValidationJSON}
                validationJSON={validations}
                changeHandler={changeHandler}
                key={generateKey()}>
                <Row>
                    <Col id="Auction" xs={12}><SectionHeading>{auctionConfig.label}</SectionHeading></Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <GLField<FormInputProps> name="eventPlace" placeholder="Event Place"
                            label="Event Place" use={FormInput} maxLength={40} />
                    </Col>

                    {getEventDate()}

                    <Col xs={4} id="eventTime">
                        <div style={{ width: '115px' }}>
                            <GLField<FormSelectProps> defaultValue={auctionDetails.eventTimeHrs} use={FormSelect} options={auctionConfig.hours} disabled={false} name="eventTimeHrs" label='Event Time' />
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '65px' }}>
                            :
                        </div>
                        <div style={{ marginLeft: '10px', marginTop: '27px', width: '115px' }}>
                            <GLField<FormSelectProps> defaultValue={auctionDetails.eventTimeMinutes} use={FormSelect} options={auctionConfig.minutes} disabled={false} name="eventTimeMinutes" />
                        </div>
                    </Col>
                </Row>
            </GLForm>
        </AuctionContainer>
    )
}

const AuctionContainer = styled.div`
    #eventTime{
        display: flex !important;
    }
`
export default Auction;